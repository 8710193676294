<template>
  <div>
    <h1>Tinkoff LockOut</h1>

    <div v-if="tabb == 'rounds'" style="display: flex;overflow-x: auto;white-space: nowrap; padding-top: 50px">
      <div style="margin-right: 40px" v-for="(round, i) in rounds" :key="i">
        <div style="display: flex;  align-items: end">
          <h2 style="margin-right: 40px">{{round.name}}</h2>
          <h2 style="font-size: 35px; color: cornflowerblue" v-if="round.status == 'before'">{{round.status}}</h2>
          <h2 style="font-size: 35px; color: orangered" class="neo_red"  v-if="round.status == 'progress'">{{round.status}}</h2>
          <h2 style="font-size: 35px; color: forestgreen" v-if="round.status == 'finished'">{{round.status}}</h2>
        </div>
        <div style="display: flex">
          <div style="margin-right: 25px;">
            <div style="margin-top: 25px; height: 60px; display: flex; flex-direction: column; justify-content: space-between" v-for="(pair, j) in round.pairs" :key="j">
              <p>{{ pair.fName }}</p>
              <p>{{ pair.sName }}</p>
            </div>
          </div>
          <div>
            <div style="margin-top: 25px; height: 60px; display: flex;" v-for="(pair, j) in round.pairs" :key="j">
              <div style="display: flex; flex-direction: column; justify-content: space-between; margin-right: 15px" v-for="(solve, k) in pair.solveMask" :key="k">
                <div v-if="solve !== 1" style="height: 19px; width: 30px; border: solid 1px white; border-radius: 5px"></div>
                <div v-if="solve == 1" style="height: 19px; width: 30px; background: #3edf4b; border: solid 1px white; border-radius: 5px"></div>
                <div v-if="solve !== 2" style="height: 19px; width: 30px; border: solid 1px white; border-radius: 5px"></div>
                <div v-if="solve == 2" style="height: 19px; width: 30px; background: #3edf4b; border: solid 1px white; border-radius: 5px"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tabb == 'table'">
      <div  style="margin-top: 10px" v-for="(value, name) in this.table" :key="name">
        <p>{{ name }}: {{ value }}</p>
      </div>
    </div>
    <div  v-if="show" id="notiff" style="position: absolute; z-index: 99; height: 100vh; width: 100vw; top: 0; left: 0; display: flex; justify-content: center; align-items: center">
      <h4 style="padding: 100px; background: orangered; border-radius: 15px">{{showText}}</h4>
    </div>
    <div  style="display: flex; position: absolute; bottom: 50px; width: calc(100vw - 100px)">
      <div  v-if="tabb != 'table'" class="neonText" style="width: 100%; margin-right: auto;  display: flex; justify-content: center; align-items: center">
        <h3>Rounds</h3>
      </div>
      <div  @click="tabb = 'rounds'"  v-if="tabb == 'table'" class="no_neo"  style="width: 100%; margin-right: auto;  display: flex; justify-content: center; align-items: center; color: white;">
        <h3>Rounds</h3>
      </div>
      <div  v-if="tabb == 'table'" class="neonText" style="width: 100%; margin-right: auto;  display: flex; justify-content: center; align-items: center">
        <h3>Scoreboard</h3>
      </div>
      <div @click="tabb = 'table'"  v-if="tabb != 'table'" class="no_neo"  style="width: 100%; margin-right: auto;  display: flex; justify-content: center; align-items: center; color: white;">
        <h3>Scoreboard</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Rounds',
  data:() => ({
    rounds: [],
    table: {},
    sols: {},
    show: false,
    showText: '',
    tabb: 'rounds'
  }),
  mounted() {
    setInterval(() => {
      fetch("http://api.duel.algocourses.ru/data")
          .then(res => res.json())
          .then(out => {
            var rounds = {}
            for (var round1 of out) {
              rounds[JSON.parse(round1).order] = []
            }
            for (var round2 of out) {
              rounds[JSON.parse(round2).order].push(round2)
            }
            for (var order in rounds) {
              this.new_data(rounds[order])
            }
          }).catch(err => { throw err });
    }, 1000);
  },
  methods : {
    new_data(data) {
      let urlParams = new URLSearchParams(window.location.search);
      console.log(urlParams.has('room')); // true
      let room = parseInt(urlParams.get('room'))
      let round_upds = data.map((x) => JSON.parse(x))

      round_upds = round_upds.map((round_upd) => {
        round_upd.pairs = round_upd.pairs.filter((pair) => {
          return !(round_upd.className === '1' && !pair.is_junior) && !(round_upd.className === '2' && pair.is_junior) && (room === pair.room || room === 0);
        })
        return round_upd
      })
      for (var it = 1; it < round_upds.length; it++) {
        round_upds[0].pairs = round_upds[0].pairs.concat(round_upds[it].pairs)
      }
      let round_upd = round_upds[0];
      while(this.rounds.length < round_upd.order) {
        this.rounds.push({})
      }
      this.$set(this.rounds[round_upd.order - 1], 'name', round_upd.name)
      this.$set(this.rounds[round_upd.order - 1], 'status', round_upd.status)
      this.$set(this.rounds[round_upd.order - 1], 'pairs', round_upd.pairs)

      let local_table = {}
      let local_sols = {}
      var rCnt = 0
      for (let round of this.rounds) {
        for (let pair of round.pairs) {
          if (!(pair.fName.split(' ').slice(0, -1).join(' ') in local_table)) {
            local_table[pair.fName.split(' ').slice(0, -1).join(' ')] = 0
          }
          if (!(pair.sName.split(' ').slice(0, -1).join(' ') in local_table)) {
            local_table[pair.sName.split(' ').slice(0, -1).join(' ')] = 0
          }
          if (!(pair.fName.split(' ').slice(0, -1).join(' ') in local_sols)) {
            local_sols[pair.fName.split(' ').slice(0, -1).join(' ')] = []
          }
          if (!(pair.sName.split(' ').slice(0, -1).join(' ') in local_sols)) {
            local_sols[pair.sName.split(' ').slice(0, -1).join(' ')] = []
          }
          var cnt = {0: 0, 1 : 0, 2 : 0}
          for (var i = 0; i < 4; i++) {
            if (pair.solveMask[i] == 1) {
              local_sols[pair.fName.split(' ').slice(0, -1).join(' ')].push({r : rCnt, t: i})
            }
            if (pair.solveMask[i] == 2) {
              local_sols[pair.sName.split(' ').slice(0, -1).join(' ')].push({r : rCnt, t: i})
            }
            cnt[pair.solveMask[i]] += (i + 1) * 100;
          }
          if (cnt[1] === cnt[2]) {
            local_table[pair.fName.split(' ').slice(0, -1).join(' ')] += 0.5
            local_table[pair.sName.split(' ').slice(0, -1).join(' ')] += 0.5
          } else if (cnt[1] > cnt[2]) {
            local_table[pair.fName.split(' ').slice(0, -1).join(' ')] += 1
          } else {
            local_table[pair.sName.split(' ').slice(0, -1).join(' ')] += 1
          }
        }
        rCnt++
      }
      if (Object.keys(this.sols).length !== 0) {
        var notifs = []
        for (let name in local_sols) {
          for (let sol of local_sols[name]) {
            if (this.sols[name] === undefined) {
              this.sols[name] = []
            }
            if (this.sols[name].filter((o) => o.r === sol.r && o.t === sol.t).length === 0) {
              notifs.push(`${name} solved task ${String.fromCharCode('A'.charCodeAt(0) + sol.t)}`)
            }
          }
        }

        if (notifs.length === 1) {
          this.show = 1
          this.showText = notifs[0]
          let musicc = ["DON.ogg",  "DORA.ogg" , "HAVA.ogg" , "MAYBE.ogg" , "PONOS.ogg"  ,"SHNUR.ogg"]
          var audio = new Audio(`http://pdf.ejudge.podvorniy.ge/${musicc[Math.floor(Math.random()*6)]}`); // path to file
          audio.play();
          setTimeout(() => { this.show = 0}, 5000)
        }
      }
      this.sols = local_sols
      this.table = Object.entries(local_table).sort(([,a],[,b]) => b-a).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.no_neo {
  border: solid 4px greenyellow;
  font-family: 'Syne Tactile', cursive;

  color: #fff;
  font-size: 30px;
}
.neo_red {
  animation: flicker-red 1.5s infinite alternate;
}
h1 {
  font-size: 45px;
  background: linear-gradient(
      #fd004c 16.7%,
      #fe9000 16.7%,
      #fe9000 33.4%,
      #fff020 33.4%,
      #fff020 50.1%,
      #3edf4b 50.1%,
      #3edf4b 66.8%,
      #3363ff 66.8%,
      #3363ff 83.5%,
      #b102b7 83.5%
  );
  -webkit-text-stroke: 0.04em #ffffff;
  -webkit-background-clip: text;
  background-clip: text;
  line-height: 1.1em;
  animation: rainbow 50s linear infinite;
  color: transparent;
}

h2 {
  margin: 0;
  color: ghostwhite;
  font-family: 'Rowdies', cursive;
  font-size: 40px
}
h2::first-letter {
  text-transform:capitalize;

}
p {
  color: ghostwhite;
  margin: 0;
  font-family: 'Alice', serif;
  font-size: 22px;
  margin-top: 5px;
  line-height: 22px;
}

h4 {
  font-family: 'Russo One', sans-serif;
  color: ghostwhite;
  margin: 0;
  font-size: 30px;
}
#notiff {
  transform: scale(3);
  animation: pulse ease-in-out 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  30% {
    transform: scale(2.5);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.neonText {
  font-family: 'Syne Tactile', cursive;

  color: #fff;
  font-size: 30px;
  box-shadow:  0 0 4px #fff,
  0 0 11px #fff,
  0 0 15px #fff,
  0 0 20px #0fa,
  0 0 30px #0fa,
  0 0 40px #0fa,
  0 0 50px #0fa,
  0 0 70px #0fa;;
}

.neonText:hover {
  animation: flicker 1.5s infinite alternate;
}


/* Flickering animation */
@keyframes flicker {

  0%, 18%, 22%, 25%, 53%, 57%, 100% {

    text-shadow:
        0 0 4px #fff,
        0 0 11px #fff,
        0 0 19px #fff,
        0 0 40px #0fa,
        0 0 80px #0fa,
        0 0 90px #0fa,
        0 0 100px #0fa,
        0 0 150px #0fa;

  }

  20%, 24%, 55% {
    text-shadow: none;
  }
}

@keyframes flicker-red {

  0%, 18%, 22%, 25%, 53%, 57%, 100% {

    text-shadow:
        0 0 4px #fff,
        0 0 11px #fff,
        0 0 19px #fff,
        0 0 40px #f0a,
        0 0 70px #f0a,
        0 0 80px #f0a,
        0 0 85px #f0a,
        0 0 95px #f0a;

  }

  20%, 24%, 55% {
    text-shadow: none;
  }
}




</style>
